<template>
  <MapViews />
</template>

<script>
  import MapViews from '@/pages/mapView/MapView.vue';

  export default {
    components: {
      MapViews
    },
    name: 'CreateFilter'
  };
</script>